import React, { useState, useMemo, useEffect } from 'react'
import 'primeicons/primeicons.css'
import { useForm } from 'react-hook-form'
import MzInput from '../../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constant'
import { Fieldset } from 'primereact/fieldset'
import { Button } from 'primereact/button'
import MzPhoneInput from '../../../common/MzForm/MzPhoneInput'
import MzAutoComplete from '../../../common/MzForm/MzAutoComplete'

const AddEditSevenEnquiryComponent = (props) => {
  const {
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    setvisibleSevenEnquiry,
    subDistrictsList,
    villagesList,
    formFieldValueMap,
    createSevenEnquiryRecord,
    isPageLevelError,
    fetchGatNoList,
    gatNosList
  } = props.SevenEnquiryProps

  const userProfile = JSON.parse(localStorage.getItem('userProfile'))
  const {
    control,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      // return userProfile || formFieldValueMap;
      return {
        ...(userProfile || formFieldValueMap),
        [FORM_FIELDS_NAME.ENQUIRY_TYPE.name]:
          formFieldValueMap?.[FORM_FIELDS_NAME.ENQUIRY_TYPE.name] ||
          'SEVEN_TWELVE'
      }
    }, [userProfile, formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.PROVINCE.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.DISTRICT.name)) {
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.DISTRICT.name), fetchSubDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.SUB_DISTRICT.name)) {
      fetchVillageList(watch(FORM_FIELDS_NAME.SUB_DISTRICT.name))
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.SUB_DISTRICT.name), fetchVillageList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.VILLAGE.name)) {
      fetchGatNoList(watch(FORM_FIELDS_NAME.VILLAGE.name))
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.VILLAGE.name), fetchGatNoList, setValue])

  const [focusedField, setFocusedField] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setValue(FORM_FIELDS_NAME?.FIRST_NAME?.name, userProfile?.firstName || '')
      setValue(FORM_FIELDS_NAME?.LAST_NAME?.name, userProfile?.lastName || '')
      setValue(FORM_FIELDS_NAME?.EMAIL?.name, userProfile?.email || '')
      // setValue(FORM_FIELDS_NAME?.MOBILE?.name, userProfile?.mobile || '');
    }
  }, [userProfile, setValue])

  const inputStyles = {
    width: '100%',
    padding: '10px',
    border: '1px solid #4caf50',
    borderRadius: '5px',
    fontSize: '16px',
    marginTop: '0.5%',
    marginBottom: '2%',
    transition: 'border-color 0.3s ease, box-shadow 0.3s ease'
  }

  const focusStyles = {
    borderColor: '#4caf50',
    outline: 'none',
    boxShadow: 'hsl(160, 85%, 36%) 0px 0px 0px 1px'
  }

  const handleFocus = (field) => {
    setFocusedField(field)
  }

  const handleBlur = () => {
    setFocusedField(null)
  }

  const getInputStyles = (field) => {
    return focusedField === field
      ? { ...inputStyles, ...focusStyles }
      : inputStyles
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name]?.message}</small>
    )
  }
  const handleClose = () => {
    setvisibleSevenEnquiry(false)
  }
  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const payload = {
        firstName: data.fname,
        lastName: data.lname,
        plot: {
          id: data.gatNo
        },
        email: data.email,
        mobileNo: data.mobile,
        description: data.description,
        enquiryType: data.enquiryType
      }
      createSevenEnquiryRecord(payload)
      setTimeout(() => {
        setvisibleSevenEnquiry(false)
      }, 2000)

      setIsLoading(false)
    } catch (error) {
      console.error('Error submitting form:', error)
      // setIsLoading(false)
    }
  }

  return (
    <>
      <div className="surface-grund flex align-items-center justify-content-center     max-w-screen overflow-auto">
        <div className="flex flex-column align-items-center justify-content-center p-3">
          <h1 className="text-center text-4xl font-bold text-green-500 ">
            Enquiry
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column text-start  border-round-sm ">
              <Fieldset legend="User Details">
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.FIRST_NAME.name}
                      label={FORM_FIELDS_NAME.FIRST_NAME.label}
                      placeholder={FORM_FIELDS_NAME.FIRST_NAME.placeholder}
                      type={FORM_FIELDS_NAME.FIRST_NAME.type}
                      isError={errors[FORM_FIELDS_NAME.FIRST_NAME.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.FIRST_NAME.name
                      )}
                      rules={FORM_FIELDS_NAME.FIRST_NAME.rules}
                      wrapperClass={'p-float-label'}
                    />
                  </div>
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.LAST_NAME.name}
                      label={FORM_FIELDS_NAME.LAST_NAME.label}
                      placeholder={FORM_FIELDS_NAME.LAST_NAME.placeholder}
                      type={FORM_FIELDS_NAME.LAST_NAME.type}
                      isError={!!errors[FORM_FIELDS_NAME.LAST_NAME.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.LAST_NAME.name
                      )}
                      rules={FORM_FIELDS_NAME.LAST_NAME.rules}
                      wrapperClass="p-float-label"
                      style={getInputStyles(FORM_FIELDS_NAME.LAST_NAME.name)}
                      onFocus={() =>
                        handleFocus(FORM_FIELDS_NAME.LAST_NAME.name)
                      }
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.EMAIL.name}
                      label={FORM_FIELDS_NAME.EMAIL.label}
                      placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                      type={FORM_FIELDS_NAME.EMAIL.type}
                      isError={!!errors[FORM_FIELDS_NAME.EMAIL.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.EMAIL.name
                      )}
                      rules={FORM_FIELDS_NAME.EMAIL.rules}
                      wrapperClass="p-float-label"
                      style={getInputStyles(FORM_FIELDS_NAME.EMAIL.name)}
                      onFocus={() => handleFocus(FORM_FIELDS_NAME.EMAIL.name)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzPhoneInput
                      control={control}
                      name={FORM_FIELDS_NAME.MOBILE.name}
                      label={FORM_FIELDS_NAME.MOBILE.label}
                      placeholder={FORM_FIELDS_NAME.MOBILE.placeholder}
                      type={FORM_FIELDS_NAME.MOBILE.type}
                      isError={!!errors[FORM_FIELDS_NAME.MOBILE.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.MOBILE.name
                      )}
                      rules={FORM_FIELDS_NAME.MOBILE.rules}
                      wrapperClass="p-float-label"
                      style={getInputStyles(FORM_FIELDS_NAME.MOBILE.name)}
                      onFocus={() => handleFocus(FORM_FIELDS_NAME.MOBILE.name)}
                      onBlur={handleBlur}
                      country="in"
                    />
                  </div>
                </div>
              </Fieldset>
              <Fieldset legend="Location Details" className="mt-4">
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem ">
                    <label className="form-label">
                      {FORM_FIELDS_NAME.ENQUIRY_TYPE.label}
                    </label>
                    <div className="flex gap-5 p-2">
                      {FORM_FIELDS_NAME.ENQUIRY_TYPE.options.map((option) => (
                        <div key={option.value} className="form-check">
                          <input
                            type="radio"
                            id={option.value}
                            name={FORM_FIELDS_NAME.ENQUIRY_TYPE.name}
                            value={option.value}
                            {...register(FORM_FIELDS_NAME.ENQUIRY_TYPE.name, {
                              required:
                                FORM_FIELDS_NAME.ENQUIRY_TYPE.rules.required
                            })}
                            className={`form-check-input ${
                              errors[FORM_FIELDS_NAME.ENQUIRY_TYPE.name]
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <label
                            htmlFor={option.value}
                            className="form-check-label"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.PROVINCE.name}
                      label={FORM_FIELDS_NAME.PROVINCE.label}
                      optionLabel={FORM_FIELDS_NAME.PROVINCE.optionLabel}
                      optionValue={FORM_FIELDS_NAME.PROVINCE.optionValue}
                      placeholder={FORM_FIELDS_NAME.PROVINCE.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.PROVINCE.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.PROVINCE.name
                      )}
                      rules={FORM_FIELDS_NAME.PROVINCE.rules}
                      suggestions={provincesList ?? []}
                      dropdown
                      // value={formFieldValueMap?.state ?? ''}
                    />
                  </div>
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.DISTRICT.name}
                      label={FORM_FIELDS_NAME.DISTRICT.label}
                      optionLabel={FORM_FIELDS_NAME.DISTRICT.optionLabel}
                      optionValue={FORM_FIELDS_NAME.DISTRICT.optionValue}
                      placeholder={FORM_FIELDS_NAME.DISTRICT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.DISTRICT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.DISTRICT.name
                      )}
                      rules={FORM_FIELDS_NAME.DISTRICT.rules}
                      suggestions={districtsList ?? []}
                      dropdown
                      // value={formFieldValueMap?.district ?? ''}
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.SUB_DISTRICT.name}
                      label={FORM_FIELDS_NAME.SUB_DISTRICT.label}
                      optionLabel={FORM_FIELDS_NAME.SUB_DISTRICT.optionLabel}
                      optionValue={FORM_FIELDS_NAME.SUB_DISTRICT.optionValue}
                      placeholder={FORM_FIELDS_NAME.SUB_DISTRICT.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.SUB_DISTRICT.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.SUB_DISTRICT.name
                      )}
                      rules={FORM_FIELDS_NAME.SUB_DISTRICT.rules}
                      suggestions={subDistrictsList ?? []}
                      dropdown
                      // value={formFieldValueMap?.taluka ?? ''}
                    />
                  </div>
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.VILLAGE.name}
                      label={FORM_FIELDS_NAME.VILLAGE.label}
                      optionLabel={FORM_FIELDS_NAME.VILLAGE.optionLabel}
                      optionValue={FORM_FIELDS_NAME.VILLAGE.optionValue}
                      placeholder={FORM_FIELDS_NAME.VILLAGE.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.VILLAGE.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.VILLAGE.name
                      )}
                      rules={FORM_FIELDS_NAME.VILLAGE.rules}
                      suggestions={villagesList ?? []}
                      dropdown
                      // value={formFieldValueMap?.village ?? ''}
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzAutoComplete
                      control={control}
                      name={FORM_FIELDS_NAME.GATNO.name}
                      label={FORM_FIELDS_NAME.GATNO.label}
                      optionLabel={FORM_FIELDS_NAME.GATNO.optionLabel}
                      optionValue={FORM_FIELDS_NAME.GATNO.optionValue}
                      placeholder={FORM_FIELDS_NAME.GATNO.placeholder}
                      isError={!!errors[FORM_FIELDS_NAME.GATNO.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.GATNO.name
                      )}
                      rules={FORM_FIELDS_NAME.GATNO.rules}
                      suggestions={gatNosList ?? []}
                      dropdown
                      // value={formFieldValueMap?.plotNumber ?? ''}
                    />
                  </div>

                  <div className="mb-3 w-17rem md:w-20rem">
                    <MzInput
                      control={control}
                      name={FORM_FIELDS_NAME.SURVEYNO.name}
                      label={FORM_FIELDS_NAME.SURVEYNO.label}
                      placeholder={FORM_FIELDS_NAME.SURVEYNO.placeholder}
                      type={FORM_FIELDS_NAME.SURVEYNO.type}
                      isError={!!errors[FORM_FIELDS_NAME.SURVEYNO.name]}
                      errorMsg={getFormErrorMessage(
                        FORM_FIELDS_NAME.SURVEYNO.name
                      )}
                      rules={FORM_FIELDS_NAME.SURVEYNO.rules}
                      wrapperClass="p-float-label"
                      style={getInputStyles(FORM_FIELDS_NAME.SURVEYNO.name)}
                      onFocus={() =>
                        handleFocus(FORM_FIELDS_NAME.SURVEYNO.name)
                      }
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="grid gap-2"></div>
              </Fieldset>
              <Fieldset legend="Price Details" className="mt-4">
                <div>
                  <i>7/12</i> - <b>50</b> Rs per Document
                </div>
                <br />
                <div>
                  <i>Index 2</i> - <b>100</b> Rs per Document
                </div>
              </Fieldset>
            </div>
            <div className="grid justify-content-center mt-3 mb-5">
              <div className="col-12 md:col-4">
                <Button
                  label="Cancel"
                  className="w-full"
                  severity="secondary"
                  onClick={() => handleClose()}
                  disabled={isLoading || isPageLevelError}
                />
              </div>
              <div className="col-12 md:col-4">
                <Button
                  type="submit"
                  label="Submit"
                  className="w-full"
                  disabled={isLoading || isPageLevelError}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddEditSevenEnquiryComponent
